import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import SectionHeader from "../../typography/SectionHeader";
import Container from "../Container";

const HeadingWithNav = ({ children, activeSection }) => {
  const sections = [
    "section1",
    "section2",
    "section3",
    "section4",
    "section5",
    "section6",
    "section7"
  ];
  const activeClasses = cn(s.active, c.bgGreen);
  return (
    <Container className={cn(s.container, "smallSpacing")}>
      <nav className={s.navContainer}>
        {sections.map((section, index) => (
          <div
            key={index}
            className={cn(s.link, c.bgLightBlue, {
              [activeClasses]: activeSection === section
            })}
          />
        ))}
      </nav>
      <Container className={s.heading}>
        <SectionHeader>{children}</SectionHeader>
      </Container>
    </Container>
  );
};

export default HeadingWithNav;
