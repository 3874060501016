import React from "react";
import s from "./style.module.css";
import Small from "../../typography/Small";
import Link from "../../typography/Link";
import Copyright from "../../typography/Copyright";
import Container from "../Container";
import { ReactComponent as Logo } from "../../assets/logos/Totaljobs-totem.svg";
import Social from "../Social";

const Footer = props => {
  return (
    <footer>
      <Container>
        <div className={s.textAndSocialContainer}>
          <div className={s.footerTextContainer}>
            <Small>Survey of 2,286 UK workers in February 2019.</Small>
            <br />
            <Small>
              Research produced and analysed by Totaljobs with the help and
              guidance of{" "}
              <Link
                href="http://www.braverstrongersmarter.com/"
                underline
                target="_blank"
                rel="noopener nofollow"
              >
                Dr Terri Simpkin
              </Link>
              , leadership and inclusion researcher and{" "}
              <Link
                href="http://www.kateatkin.com/"
                underline
                target="_blank"
                rel="noopener nofollow"
              >
                Kate Atkin
              </Link>
              , speaker facilitator and imposter syndrome researcher.
            </Small>
            <br />
            <Small>
              Created in collaboration with{" "}
              <Link
                href="https://beyondwordsstudio.com/"
                underline
                target="_blank"
                rel="noopener nofollow"
              >
                Beyond Words Studio.
              </Link>
            </Small>
          </div>
          <div className={s.socialContainer}>
            <Social />
            <Logo />
          </div>
        </div>
        <div className={s.navContainer}>
          <Link
            href="https://www.totaljobs.com/about/about-us"
            color="Green"
            rel="nofollow"
          >
            About&nbsp;Us
          </Link>
          <Link
            href="https://www.totaljobs.com/about/contact-us"
            color="Green"
            rel="nofollow"
          >
            Contact&nbsp;Us
          </Link>
          <Link
            href="https://www.totaljobs.com/about/privacy-policy"
            color="Green"
            rel="nofollow"
          >
            Privacy&nbsp;Policy
          </Link>
          <Link
            href="https://www.totaljobs.com/about/terms-and-conditions"
            color="Green"
            rel="nofollow"
          >
            Terms&nbsp;and&nbsp;Conditions
          </Link>
          <Link
            href="https://www.totaljobs.com/about/cookies"
            color="Green"
            rel="nofollow"
          >
            Cookies
          </Link>
        </div>
        <Copyright />
      </Container>
    </footer>
  );
};

export default Footer;
