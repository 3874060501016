import React, { Fragment } from "react";
import Container from "../../components/Container";
import List from "../../components/List";
import HeadingWithNav from "../../components/HeadingWithNav";
import Appear from "../../components/Appear";

const Section6 = props => {
  return (
    <Fragment>
      <Appear id="section6">
        <HeadingWithNav activeSection="section6">
          5 ways to beat Imposter Phenomenon
        </HeadingWithNav>
      </Appear>
      <Container className="extraLargeSpacing">
        <List
          listItems={[
            {
              title: "1. Call it out",
              content:
                "This is the most important step. Be clear that this isn’t a simple case of self-doubt, but is an ongoing feeling of not being good enough despite successes.",
              icon: "Icon_1"
            },
            {
              title: "2. Get a mentor",
              content:
                "Have someone who can challenge your imposter excuses and give evidence as to where the praise and achievement really belongs.",
              icon: "Icon_2"
            },
            {
              title: "3. List your achievements",
              content:
                "Honestly and clearly understanding your abilities, without 'excuses', will help you get comfortable with them. Taking pride in your work is very different from hubris.",
              icon: "Icon_3"
            },
            {
              title: "4. Change your language",
              content:
                'Do you dismiss praise with "Thanks, but it was nothing"? Stop at the "but". In your head, now start to "and" your positive feedback instead: "...and I used this skill or strength."',
              icon: "Icon_4"
            },
            {
              title: "5. Assess your expectations",
              content:
                "Challenge your own assumptions about what’s really expected. How good is good enough? Moving away from your own lofty goals will help reduce the need to be perfect.",
              icon: "Icon_5"
            }
          ]}
        />
      </Container>
    </Fragment>
  );
};

export default Section6;
