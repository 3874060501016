import React from "react";
import BodyText from "../../typography/BodyText";
import Container from "../../components/Container";
import HeadingWithNav from "../../components/HeadingWithNav";
import Quote from "../../components/Quote";
import Columns from "../../components/Columns";
import Column from "../../components/Column";
import Scatter from "../../components/Scatter";
import Appear from "../../components/Appear";

const Section3 = props => {
  return (
    <Appear id="section3" className="extraLargeSpacing">
      <HeadingWithNav activeSection="section3">
        How to spot an ‘imposter’
      </HeadingWithNav>
      <Columns>
        <Column>
          <BodyText className="smallSpacing">
            Imposters see themselves differently. By looking at the words people
            use to describe themselves, we can get a good sense of how Imposter
            Phenomenon warps our self-perceptions in the workplace.
          </BodyText>
        </Column>
        <Column>
          <BodyText className="smallSpacing">
            How are people with Imposter Phenomenon likely to describe
            themselves?
          </BodyText>
        </Column>
        <Quote
          content={
            "I have always felt that I'm faking it. At university I worried that one day, someone would realise I wasn't meant to be there – and then I graduated top of my class."
          }
          name={"Charlotte, healthcare administration"}
        />
      </Columns>
      <Container isSmall>
        <Scatter
          data={[
            { trait: "Doubtful", imposter: 30, notImposter: 13 },
            { trait: "Scared", imposter: 23, notImposter: 10 },
            { trait: "Phoney", imposter: 17, notImposter: 5 },
            {
              trait: "Unworthy",
              imposter: 20,
              notImposter: 10,
              labelOffset: { x: 2, y: 1.5 }
            },
            { trait: "Dissatisfied", imposter: 42, notImposter: 33 },
            { trait: "Blameworthy", imposter: 12, notImposter: 6 },
            { trait: "Inconspicuous", imposter: 16, notImposter: 10 },
            {
              trait: "Lucky",
              imposter: 19,
              notImposter: 14,
              labelOffset: { x: -12, y: 4 },
              labelCover: { x: -12, y: 0, width: 10, height: 5, color: "white" }
            },
            { trait: "Indebted", imposter: 8, notImposter: 4 },
            {
              trait: "Visible",
              imposter: 17,
              notImposter: 22,
              labelOffset: { x: 1, y: -1 },
              labelCover: {
                x: 2,
                y: -5,
                width: 10,
                height: 5,
                color: "#bfe5e5"
              }
            },
            {
              trait: "Acknowledged",
              imposter: 22,
              notImposter: 30,
              labelOffset: { x: -31, y: 1 }
            },
            {
              trait: "Secure",
              imposter: 13,
              notImposter: 21,
              labelOffset: { x: 1, y: 4 },
              labelCover: {
                x: 3,
                y: 0,
                width: 8,
                height: 5,
                color: "white"
              }
            },
            {
              trait: "Praiseworthy",
              imposter: 13,
              notImposter: 22,
              labelOffset: { x: -27, y: 1 }
            },
            {
              trait: "Proud",
              imposter: 23,
              notImposter: 33,
              labelOffset: { x: -2, y: -4 }
            },
            {
              trait: "Justified",
              imposter: 14,
              notImposter: 25,
              labelOffset: { x: -19, y: 1 }
            },
            {
              trait: "Deserving",
              imposter: 15,
              notImposter: 27,
              labelOffset: { x: 2, y: -3 },
              labelCover: {
                x: 2,
                y: -7,
                width: 15,
                height: 5,
                color: "#bfe5e5"
              }
            },
            {
              trait: "Worthy",
              imposter: 15,
              notImposter: 28,
              labelOffset: { x: -17, y: 1 }
            },
            {
              trait: "Confident",
              imposter: 27,
              notImposter: 46,
              labelOffset: { x: -22, y: 1 }
            }
          ]}
          layerColor="#bfe5e5"
        />
        <BodyText className="smallSpacing">
          Imposter Phenomenon is tricksy. Ironically, it’s often associated with
          high-achievers who struggle to recognise their own capabilities or
          past successes.
        </BodyText>
        <BodyText noPadding className="smallSpacing">
          And these people may also be highly skilled at concealing this fact
          and appear to be very confident, ambitious professionals.
        </BodyText>
      </Container>
    </Appear>
  );
};

export default Section3;
