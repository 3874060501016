import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const ChartGridLines = ({ left, hasLeftBorder, halfWidth }) => {
  return (
    <div
      className={cn(
        s.container,
        { [s.hasLeftBorder]: hasLeftBorder },
        { [s.halfWidth]: halfWidth }
      )}
      style={{ left: left }}
    />
  );
};

export default ChartGridLines;
