import React from "react";
import s from "../style.module.css";

import { ReactComponent as Facebook } from "../../../assets/social/facebook.svg";
import { ReactComponent as Twitter } from "../../../assets/social/twitter.svg";

const SocialIcon = ({ type }) => {
  switch (type) {
    case "facebook":
      return <Facebook className={s.socialIcon} />;
    case "twitter":
      return <Twitter className={s.socialIcon} />;
    default:
      return null;
  }
};

export default SocialIcon;
