import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import withOnScreen from "../../hoc/withOnScreen";

import { ReactComponent as Icon1 } from "../../assets/listIcons/Icon_1.svg";
import { ReactComponent as Icon2 } from "../../assets/listIcons/Icon_2.svg";
import { ReactComponent as Icon3 } from "../../assets/listIcons/Icon_3.svg";
import { ReactComponent as Icon4 } from "../../assets/listIcons/Icon_4.svg";
import { ReactComponent as Icon5 } from "../../assets/listIcons/Icon_5.svg";

const ListIcon = ({ name, className, onScreen }) => {
  switch (name) {
    case "Icon_1":
      return <Icon1 className={cn({ [s.onScreen]: onScreen })} />;
    case "Icon_2":
      return <Icon2 className={cn({ [s.onScreen]: onScreen })} />;
    case "Icon_3":
      return <Icon3 className={cn({ [s.onScreen]: onScreen })} />;
    case "Icon_4":
      return <Icon4 className={cn({ [s.onScreen]: onScreen })} />;
    case "Icon_5":
      return <Icon5 className={cn({ [s.onScreen]: onScreen })} />;

    default:
      return;
  }
};

export default withOnScreen(ListIcon, 1);
