import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import BodyText from "../../typography/BodyText";
import ListHeader from "../../typography/ListHeader";
import ListIcon from "../ListIcon";
import Container from "../Container";

const ListItem = ({ title, content, icon }) => {
  return (
    <Container className={cn(s.container, "smallSpacing")}>
      <div className={s.iconContainer}>
        <ListIcon name={icon} />
      </div>
      <div className={s.textContainer}>
        <ListHeader>{title}</ListHeader>
        <BodyText>{content}</BodyText>
      </div>
    </Container>
  );
};

export default ListItem;
