import React from "react";
import BodyText from "../../typography/BodyText";
import Link from "../../typography/Link";
import Container from "../../components/Container";
import HeadingWithNav from "../../components/HeadingWithNav";
import Quote from "../../components/Quote";
import Columns from "../../components/Columns";
import Column from "../../components/Column";
import DivergingBar from "../../components/DivergingBar";
import BarChart from "../../components/BarChart";
import Appear from "../../components/Appear";

const Section2 = props => {
  return (
    <Appear id="section2" className="extraLargeSpacing">
      <HeadingWithNav activeSection="section2">Double vision</HeadingWithNav>
      <Container isSmall>
        <BodyText className="smallSpacing">
          Imposter Phenomenon is about two things: how we see ourselves and how
          we think others see us. It can affect anyone. But wherever a person
          feels they’re in the minority at work, imposter feelings are
          especially likely to grow.
        </BodyText>
      </Container>
      <Container className="largeSpacing">
        <DivergingBar
          title="Which groups feel more like an imposter?"
          data={[
            { label: "Men", value: -5, color: "Teal" },
            { label: "Women", value: 5, color: "Teal" },
            {
              label: "Gay",
              labelColor: "White",
              value: 7,
              color: "Maroon",
              offsetLabel: true
            },
            {
              label: "Bisexual",
              labelColor: "White",
              value: 8,
              color: "Maroon",
              offsetLabel: true
            },
            { label: "Straight", value: -1, color: "Maroon" },
            {
              label: "Baby boomers",
              value: -7,
              color: "Orange",
              offsetLabel: true
            },
            { label: "Generation X", value: 1, color: "Orange" },
            { label: "Millennials", value: 4, color: "Orange" }
          ]}
        />
      </Container>
      <Columns>
        <Column>
          <BodyText className="smallSpacing">
            Women, for example, are still{" "}
            <Link
              href="https://www.ft.com/content/ac1449b8-79f7-11e8-bc55-50daf11b720d"
              underline
              target="blank"
            >
              under represented
            </Link>{" "}
            on company boards. So a female board member may question how she got
            there (“Was I just lucky?”) and how other people view her ability to
            do the job (“Do they think I’m good enough?”).
          </BodyText>
        </Column>
        <Quote
          content={
            "When I meet people from other companies with a similar job to mine I always think that they're better than me – more trained, more knowledgeable, more confident."
          }
          name="Anonymous, senior manager in electronics"
        />
        <Column>
          <BodyText className="smallSpacing">
            <Link
              href="https://www.totaljobs.com/insidejob/male-midwives-men-changing-rules/"
              underline
              target="blank"
            >
              Male midwives
            </Link>
            , finding themselves in the minority, might also begin to question
            their capabilities (“Am I supposed to be doing this job?”).
          </BodyText>
          <BodyText className="smallSpacing">
            We can start believing we’re just not as good as the people we do
            see as ‘fitting in’. And we may not even realise we’re doing it, let
            alone know the consequences.
          </BodyText>
        </Column>
      </Columns>
      <Container isSmall className="largeSpacing">
        <BarChart
          title="How many managers feel like imposters?"
          data={[
            { value: 68, barLabel: "Senior managers" },
            { value: 71, barLabel: "Middle managers" },
            { value: 77, barLabel: "Junior managers" }
          ]}
          barColor="Green"
        />
      </Container>
      <Container isSmall className="smallSpacing">
        <BodyText className="smallSpacing">
          According to our survey, the lower down the ladder you are, the higher
          your chances of feeling like an imposter. Self-doubt will be a strong
          ingredient here, as new managers feel under greater scrutiny and a
          desire to prove themselves.
        </BodyText>
        <BodyText className="smallSpacing">
          But what’s interesting is that a whopping 68% of senior managers still
          experience imposter feelings. Indeed,{" "}
          <Link
            href="http://www.paulineroseclance.com/pdf/ip_high_achieving_women.pdf"
            underline
            target="blank"
          >
            past research
          </Link>{" "}
          suggests that the more successful you are, the more prone to Imposter
          Phenomenon you can be.
        </BodyText>
        <BodyText noPadding className="smallSpacing">
          Understanding Imposter Phenomenon early on in your career can help
          avoid it later.
        </BodyText>
      </Container>
    </Appear>
  );
};

export default Section2;
