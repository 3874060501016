import React from "react";
import BodyText from "../../typography/BodyText";
import Container from "../../components/Container";
import HeadingWithNav from "../../components/HeadingWithNav";
import BarChart from "../../components/BarChart";
import Appear from "../../components/Appear";

const Section5 = props => {
  return (
    <Appear id="section5" className="extraLargeSpacing">
      <HeadingWithNav activeSection="section5">
        The vicious cycle of great expectations
      </HeadingWithNav>
      <Container isSmall>
        <BodyText className="smallSpacing">
          Imposter Phenomenon strikes when we become anxious about whether we
          meet expectations. But whose expectations? Usually our own...
        </BodyText>
      </Container>
      <Container className="largeSpacing">
        <BarChart
          title="How do we measure our own success?"
          barColor="Green"
          data={[
            {
              value: 63,
              barLabel:
                "Meeting my own expectations even if they're more than others' expectations"
            },
            {
              value: 43,
              barLabel: "Going above and beyond my manager's expectations"
            },
            {
              value: 31,
              barLabel: "Meeting my manager’s expectations"
            }
          ]}
        />
      </Container>
      <Container isSmall>
        <BodyText className="smallSpacing">
          Thanks to their exaggerated fear of failure, people who feel like
          imposters tend to set sky-high expectations for themselves. Often much
          higher than what’s required.
        </BodyText>
        <BodyText className="smallSpacing">
          By doing so, they set themselves up to fail. Even when, in fact,
          they’ve delivered. So as well as shrugging off praise they deserve,
          they’ll feel deflated if they fail to reach those standards.
        </BodyText>
        <BodyText className="smallSpacing" noPadding>
          And they take setbacks far worse than they should. Because this
          feeling of failure only seems to confirm their imposter feelings.
          Which puts the vicious cycle in motion again...
        </BodyText>
      </Container>
    </Appear>
  );
};

export default Section5;
