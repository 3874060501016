import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Container = ({ children, isSmall, noPadding, noMargin, className }) => {
  return (
    <div
      className={cn(
        s.container,
        { [s.isSmall]: isSmall },
        { [s.noPadding]: noPadding },
        { [s.noMargin]: noMargin },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Container;
