import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const ToolTip = ({ children, className, ...rest }) => {
  return (
    <p className={cn(t.toolTip, c.txtBlack, className)} {...rest}>
      {children}
    </p>
  );
};

export default ToolTip;
