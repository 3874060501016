import React, { useState, useMemo, Fragment } from "react";
import s from "./style.module.css";
import ColoredLayers from "./Elements/ColoredLayers";
import TDivider from "./Elements/TDivider";
import Plot from "./Elements/Plot";
import Axis from "./Elements/Axis";
import BackgroundLines from "./Elements/BackgroundLines";
import Controls from "./Elements/Controls";
import ChartTitle from "../ChartTitle";
import BodyText from "../../typography/BodyText";
import withOnScreen from "../../hoc/withOnScreen";

const Scatter = ({ data, layerColor, onScreen }) => {
  const [highlightImposters, setHighlightImposters] = useState(true);
  const [hasBeenSwitched, sethasBeenSwitched] = useState(false);

  const max = 50;
  const interval = 10;
  const chartBasePoint = [60, 105];

  const translatePoint = (x, y) => {
    const xPlot = chartBasePoint[0] + x - y;
    const yPlot = chartBasePoint[1] - x - y;
    return { x: xPlot, y: yPlot };
  };

  const plotData = data.map(({ imposter, notImposter, ...rest }) => {
    return {
      ...translatePoint(imposter, notImposter),
      imposter,
      notImposter,
      active: highlightImposters
        ? imposter > notImposter
        : notImposter > imposter,
      ...rest
    };
  });

  const switchHighlight = newValue => {
    if (newValue !== highlightImposters) {
      setHighlightImposters(newValue);
      sethasBeenSwitched(true);
    }
  };

  return (
    <div className={s.outerContainer}>
      <ChartTitle
        title="Breakdown of words used by imposters vs. non-imposters"
        isCentered
      />
      <BodyText isCentered>show me words used most by:</BodyText>
      <Controls
        highlightImposters={highlightImposters}
        switchHighlight={switchHighlight}
      />
      {useMemo(
        () => (
          <Fragment>
            <svg className={s.container} viewBox="0 0 120 120">
              <BackgroundLines chartBasePoint={chartBasePoint} />
              <ColoredLayers
                translatePoint={translatePoint}
                colors={["white", layerColor]}
                interval={interval}
                max={max}
              />
              <TDivider
                color={"#292a36"}
                strokeWidth={0.15}
                chartBasePoint={chartBasePoint}
              />
              <Plot
                data={plotData}
                translatePoint={translatePoint}
                onScreen={onScreen}
                hasBeenSwitched={hasBeenSwitched}
              />
              <Axis
                translatePoint={translatePoint}
                chartBasePoint={chartBasePoint}
                interval={interval}
                max={max}
              />
            </svg>
          </Fragment>
        ),
        [highlightImposters, onScreen]
      )}
    </div>
  );
};

export default withOnScreen(Scatter, 0.05);
