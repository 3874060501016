import React from "react";
import s from "./style.module.css";
import SocialLink from "./Elements/SocialLink";

const sharingCopy = {
  twitter:
    "7 in 10 UK workers have felt like an imposter. Find out what’s behind this trick of the mind and how to beat it with @totaljobsuk"
};

const Social = props => {
  return (
    <div className={s.container}>
      <SocialLink
        type="twitter"
        href={`https://twitter.com/intent/tweet?text=${
          sharingCopy.twitter
        }&url=${window.location.href}`}
      />
      <SocialLink
        type="facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${
          window.location.href
        }`}
      />
    </div>
  );
};

export default Social;
