import React from "react";
import BodyText from "../../typography/BodyText";
import Container from "../../components/Container";
import CirclesWithPercentage from "../../components/CirclesWithPercentage";
import HeadingWithNav from "../../components/HeadingWithNav";
import Quote from "../../components/Quote";
import Columns from "../../components/Columns";
import Column from "../../components/Column";
import Appear from "../../components/Appear";

const Section1 = props => {
  return (
    <Appear id="section1" className="extraLargeSpacingPlus">
      <HeadingWithNav activeSection="section1">
        What is Imposter Phenomenon?
      </HeadingWithNav>
      <Container isSmall>
        <BodyText className="smallSpacing">
          Often (mistakenly) referred to as ‘imposter syndrome’, Imposter
          Phenomenon haunts our personal and professional lives. It’s a fear of
          failure. It’s a fear of success. It’s a fear of being found out by
          others as being simply not good enough. Chances are, you’re familiar
          with it.
        </BodyText>
        <CirclesWithPercentage
          percentage={70}
          label={"of UK workers say they have experienced Imposter Phenomenon"}
        />
      </Container>
      <Columns>
        <Column>
          <BodyText className="smallSpacing">
            Totaljobs’ new survey has revealed that 7 in 10 UK workers have
            experienced Imposter Phenomenon at some time. Yet it’s one of the
            most misunderstood challenges that we face in the workplace.
          </BodyText>
        </Column>
        <Column>
          <BodyText noPadding>
            This is not self-doubt, which fades as we gain experience. Imposter
            Phenomenon is a trick of the mind that’s far more complex and
            persistent. It can hold us hostage time and time again, even when
            you have a track record of success.
          </BodyText>
        </Column>
        <Quote
          content={`When I was applying for my current role I remember thinking "This is too much – I hope I don't get it, I've got no chance."`}
          name="Steve, senior manager in transportation"
          toolTip="Check out some quotes from other 'imposters'"
        />
      </Columns>
    </Appear>
  );
};

export default Section1;
