import React from "react";
import { useInView } from "react-intersection-observer";

const withOnScreen = (WrappedComponent, threshold = 0.75) => {
  const WithOnScreen = props => {
    const [ref, inView] = useInView({
      threshold: threshold,
      triggerOnce: true
    });

    return (
      <div ref={ref}>
        <WrappedComponent {...props} onScreen={inView} />
      </div>
    );
  };
  return WithOnScreen;
};

export default withOnScreen;
