import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const Small = ({ children, ...rest }) => {
  return (
    <small className={cn(t.small, c.txtBlack)} {...rest}>
      {children}
    </small>
  );
};

export default Small;
