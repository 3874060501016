import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import Small from "../../typography/Small";

const Legend = ({ items }) => {
  return (
    <div className={s.legendContainer}>
      {items.map(({ color, label }, index) => (
        <div key={index} className={s.legendItem}>
          <div
            className={cn(
              s.legendKey,
              { [s.innerBarLegend]: index === 1 },
              {
                [c[`bg${color}`]]: color
              }
            )}
          />
          <Small>{label}</Small>
        </div>
      ))}
    </div>
  );
};

export default Legend;
