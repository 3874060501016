import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import Subtitle from "../../typography/Subtitle";
import Container from "../../components/Container";
import Related from "../../components/Related";

import subjectToChange from "../../assets/related/subjectToChange.jpg";
import workEnemies from "../../assets/related/workEnemies.png";
import workSpouses from "../../assets/related/workSpouses.png";
import howLoveWorks from "../../assets/related/howLoveWorks.png";

const KeepExploring = props => {
  const relatedCopy = [
    {
      title: "Subject to Change",
      image: subjectToChange,
      copy:
        "Do you hide your true self at work to win praise, promotion or even just acceptance? It's called 'covering'.",
      link: "https://www.totaljobs.com/insidejob/covering-at-work/"
    },
    {
      title: "The World of Work Enemies",
      image: workEnemies,
      copy:
        "Why do our working relationships turn toxic? And what can we do to fix them?",
      link: "https://www.totaljobs.com/insidejob/work-enemies/"
    },
    {
      title: "The World of Work Spouses",
      image: workSpouses,
      copy: "We all have that one colleage who makes work more bearable'.",
      link: "https://www.totaljobs.com/insidejob/work-spouse/#first-dates"
    },
    {
      title: "How Love Works",
      image: howLoveWorks,
      copy: "Revealed: the secrets of the UK's workplace romances.",
      link: "https://www.totaljobs.com/insidejob/how-love-works/"
    }
  ];

  return (
    <section className={cn(c.bgLightBlueWithOpacity, s.container)}>
      <Container>
        <Subtitle className="smallSpacing">
          Keep exploring your work-self&hellip;
        </Subtitle>
        {relatedCopy.map(({ title, image, link, copy }, index) => (
          <Related key={index} title={title} image={image} link={link}>
            {copy}
          </Related>
        ))}
      </Container>
    </section>
  );
};

export default KeepExploring;
