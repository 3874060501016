import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const ChartScale = ({
  children,
  position,
  internal,
  outer,
  className,
  ...rest
}) => {
  return (
    <span
      className={cn(
        t.chartScale,
        { [t.innerScale]: internal },
        { [t.outerScale]: outer },
        c.txtLightGrey,
        className
      )}
      style={{ left: position }}
      {...rest}
    >
      {children}
    </span>
  );
};

export default ChartScale;
