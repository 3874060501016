import React from "react";
import s from "./style.module.css";
import ListItem from "../ListItem";

const List = ({ listItems }) => {
  return (
    <div className={s.list}>
      {listItems.map(({ title, content, icon }, index) => (
        <ListItem key={index} title={title} content={content} icon={icon} />
      ))}
    </div>
  );
};

export default List;
