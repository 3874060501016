import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import Stat from "../../typography/Stat";
import Quotation from "../../typography/Quotation";

const PercentageWithLabel = ({ percentage, label }) => {
  return (
    <div>
      <div className={s.percentageAndBar}>
        <Stat>{percentage}%</Stat>
        <div className={cn(s.bar, c.bgGreen)} />
      </div>
      <div className={s.label}>
        <Quotation>{label}</Quotation>
      </div>
    </div>
  );
};

export default PercentageWithLabel;
