import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const HighlightedText = ({ children }) => {
  return (
    <p className={t.hightlightContainer}>
      <span className={cn(t.body, c.txtBlack, c.bgLightBlue, t.highlight)}>
        {children}
      </span>
    </p>
  );
};

export default HighlightedText;
