import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const Stat = ({ children, color = "black", ...rest }) => {
  return (
    <span className={cn(t.largeStat, { [c[`txt-${color}`]]: color })} {...rest}>
      {children}
    </span>
  );
};

export default Stat;
