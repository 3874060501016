import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const Copyright = props => {
  return (
    <small className={cn(t.copyright, c.txtBlack)}>
      &#169; Copyright and database rights Totaljobs Group Ltd 2019
    </small>
  );
};

export default Copyright;
