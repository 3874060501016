import React, { Fragment } from "react";
import s from "../style.module.css";
import c from "../../../colors.module.css";
import cn from "classnames";

const Plot = ({ data, color, onScreen, hasBeenSwitched }) => {
  return (
    <Fragment>
      {data.map(
        (
          {
            trait,
            x,
            y,
            imposter,
            notImposter,
            active,
            labelOffset,
            labelCover
          },
          index
        ) => (
          <Fragment key={index}>
            <circle
              className={cn(
                s.dot,
                { [s.onScreen]: onScreen },
                { [s.active]: active },
                {
                  [s.loaded]: imposter > notImposter && !hasBeenSwitched
                }
              )}
              stroke="white"
              strokeWidth="0.1"
              style={{ transitionDelay: `${Math.random()}s` }}
              cx={x}
              cy={y}
              r="1.5"
              aria-label={
                active
                  ? `${trait}. Imposters ${imposter}%. Non-imposters ${notImposter}%`
                  : ""
              }
            />

            <Fragment>
              {labelCover && (
                <rect
                  className={cn(
                    s.label,
                    { [s.onScreen]: onScreen },
                    { [s.active]: active },
                    {
                      [s.loaded]: imposter > notImposter && !hasBeenSwitched
                    }
                  )}
                  fill={labelCover.color}
                  width={labelCover.width}
                  height={labelCover.height}
                  x={x + labelCover.x}
                  y={y + labelCover.y}
                />
              )}
              <text
                className={cn(
                  s.label,
                  { [s.onScreen]: onScreen },
                  { [s.active]: active },
                  {
                    [s.loaded]: imposter > notImposter && !hasBeenSwitched
                  },
                  c.txtBlack
                )}
                x={x + (labelOffset ? labelOffset.x : 3)}
                y={y + (labelOffset ? labelOffset.y : 1)}
                aria-label={
                  active
                    ? `${trait}. Imposters ${imposter}%. Non-imposters ${notImposter}%`
                    : ""
                }
              >
                {trait}
              </text>
            </Fragment>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default Plot;
