import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const SectionHeader = ({ children, className, ...rest }) => {
  return (
    <h2 className={cn(t.sectionHeader, c.txtBlack, className)} {...rest}>
      {children}
    </h2>
  );
};

export default SectionHeader;
