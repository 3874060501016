import React from "react";
import s from "../style.module.css";
import cn from "classnames";
import Arrow from "../../Arrow";
import Container from "../../Container";
import ChartScale from "../../../typography/ChartScale";

const Legend = props => {
  return (
    <Container noPadding noMargin className={s.legendContainer}>
      <div className={cn(s.legendItem, s.leftlegendItem)}>
        <ChartScale className={s.legendLabel}>
          groups that feel less like imposters
        </ChartScale>
        <div className={s.arrowContainer}>
          <Arrow className={s.arrowLeft} />
        </div>
      </div>
      <div className={cn(s.legendItem, s.rightlegendItem)}>
        <ChartScale className={s.legendLabel}>
          groups that feel more like imposters
        </ChartScale>
        <div className={s.arrowContainer}>
          <Arrow right className={s.arrowRight} />{" "}
        </div>
      </div>
    </Container>
  );
};

export default Legend;
