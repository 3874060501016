import React, { Fragment } from "react";
import ChartTitle from "../ChartTitle";
import ChartArea from "./Elements/ChartArea";
import Legend from "./Elements/Legend";
import Bar from "../Bar";
import Container from "../Container";

const DivergingBar = ({ title, data = [] }) => {
  return (
    <Fragment>
      <Container isSmall noPadding>
        <ChartTitle title={title} isCentered />
      </Container>
      <Legend />
      <ChartArea>
        {data.map(({ label, labelColor, value, color, offsetLabel }, index) => (
          <Bar
            key={index}
            start={50}
            value={value}
            labelColor={labelColor}
            barColor={color}
            label={label}
            offsetLabel={offsetLabel}
            audioLabel={`${label} ${value}%`}
          />
        ))}
      </ChartArea>
    </Fragment>
  );
};

export default DivergingBar;
