import React, { Fragment } from "react";

const ColoredLayers = ({
  translatePoint,
  colors = ["white", "lightblue"],
  interval = 10,
  max = 50
}) => {
  const plotLayer = (start, end) => {
    const point = translatePoint(start, end);
    return `${point.x},${point.y}`;
  };

  const createPath = (start, end) => {
    const pattern = [
      [start, 0],
      [end, 0],
      [end, end],
      [0, end],
      [0, start],
      [start, start],
      [start, 0]
    ];
    return pattern
      .map(([start, end]) => {
        return plotLayer(start, end);
      })
      .join(" ");
  };

  const layers = new Array(Math.ceil(max / interval))
    .fill("")
    .map((d, index) => {
      return createPath(index * interval, (index + 1) * interval);
    });

  return (
    <Fragment>
      {layers.map((point, index) => (
        <polygon
          key={index}
          points={point}
          fill={index % 2 ? colors[0] : colors[1]}
        />
      ))}
    </Fragment>
  );
};

export default ColoredLayers;
