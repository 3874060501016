import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const Link = ({ children, underline, className, color = "Black", ...rest }) => {
  return (
    <a
      className={cn(
        { [t.underline]: underline },
        { [c[`txt${color}`]]: color },
        className
      )}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Link;
