import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import ChartLabel from "../../typography/ChartLabel";
import InternalBar from "../InternalBar";
import withOnScreen from "../../hoc/withOnScreen";

const Bar = ({
  label,
  labelColor = "Black",
  value,
  barColor,
  start = 0,
  innerValue,
  innerBarColor,
  offsetLabel,
  onScreen,
  audioLabel
}) => {
  const isNegative = value < 0;
  const width = start ? (Math.abs(value) / 20) * 100 : value;
  const position = isNegative ? start - width : start;
  const labelPosition = isNegative
    ? { right: `${100 - position}%` }
    : { left: `${start + width}%` };
  return (
    <div className={cn(s.barContainer, c.bgLightBlue)}>
      {innerValue && (
        <InternalBar
          value={innerValue}
          color={innerBarColor}
          onScreen={onScreen}
          audioLabel={audioLabel}
        />
      )}
      <div
        className={cn(
          s.bar,
          { [c[`bg${barColor}`]]: barColor },
          { [s.isNegative]: isNegative },
          { [s.onScreen]: onScreen }
        )}
        aria-label={audioLabel}
        style={{ width: `${width}%`, left: `${position}%` }}
      />
      <ChartLabel
        style={labelPosition}
        className={cn(
          s.label,
          { [s.isNegative]: isNegative },
          {
            [c[`txt${labelColor}`]]: labelColor
          },
          { [s.offsetLabel]: offsetLabel },
          { [s.offsetLabelBabyBoomer]: label === "Baby boomers" },

          { [s.onScreen]: onScreen }
        )}
      >
        {label}
      </ChartLabel>
    </div>
  );
};

export default withOnScreen(Bar);
