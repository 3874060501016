import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { ReactComponent as ArrowSvg } from "../../assets/arrow.svg";

const Arrow = ({ right, className }) => {
  return <ArrowSvg className={cn(s.arrow, { [s.right]: right }, className)} />;
};

export default Arrow;
