import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import withOnScreen from "../../hoc/withOnScreen";

const Appear = ({ children, className, onScreen, ...rest }) => {
  return (
    <section
      {...rest}
      className={cn(s.container, { [s.onScreen]: onScreen }, className)}
    >
      {children}
    </section>
  );
};

export default withOnScreen(Appear, 0.05);
