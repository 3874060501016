import React, { Fragment } from "react";
import s from "../style.module.css";
import ChartScale from "../../../typography/ChartScale";
import ChartGridLines from "../../ChartGridLines";

const ChartArea = ({ children }) => {
  const innerScaleNegative = [8, 6, 4, 2];
  const innerScalePositive = [2, 4, 6, 8];
  return (
    <Fragment>
      <div className={s.chartScale}>
        <ChartScale>-10%</ChartScale>
        {innerScaleNegative.map((position, index) => (
          <ChartScale key={index} internal position={`${(index + 1) * 10}%`}>
            -{position}%
          </ChartScale>
        ))}
        <ChartScale className={s.scaleCenter}>
          % change from UK average
        </ChartScale>
        {innerScalePositive.map((position, index) => (
          <ChartScale
            key={index}
            internal
            position={`${(index + 1) * 10 + 50}%`}
          >
            {position}%
          </ChartScale>
        ))}
        <ChartScale outer>10%</ChartScale>
      </div>
      <div className={s.chartArea}>
        <ChartGridLines halfWidth />
        <ChartGridLines left="50%" hasLeftBorder halfWidth />
        {children}
      </div>
    </Fragment>
  );
};

export default ChartArea;
