import React, { Fragment } from "react";
import s from "../style.module.css";
import ChartScale from "../../../typography/ChartScale";
import ChartGridLines from "../../ChartGridLines";

const ChartArea = ({ children }) => {
  const innerScale = ["20%", "40%", "60%", "80%"];
  return (
    <Fragment>
      <div className={s.chartScale}>
        <ChartScale>0%</ChartScale>
        {innerScale.map((position, index) => (
          <ChartScale key={index} internal position={position}>
            {position}
          </ChartScale>
        ))}
        <ChartScale outer>100%</ChartScale>
      </div>
      <div className={s.chartArea}>
        <ChartGridLines hasLeftBorder />
        {children}
      </div>
    </Fragment>
  );
};

export default ChartArea;
