import React from "react";
import BodyText from "../../typography/BodyText";
import Link from "../../typography/Link";
import Container from "../../components/Container";
import HeadingWithNav from "../../components/HeadingWithNav";
import Quote from "../../components/Quote";
import Columns from "../../components/Columns";
import Column from "../../components/Column";
import BarChart from "../../components/BarChart";
import Appear from "../../components/Appear";

const Section7 = props => {
  return (
    <Appear id="section7" className="largeSpacing">
      <HeadingWithNav activeSection="section7">
        How employers can help us to feel less like imposters
      </HeadingWithNav>
      <Container isSmall>
        <BodyText className="smallSpacing">
          Managers, know your people. Are colleagues working long hours or
          procrastinating on a project? Check whether their imposter chatter has
          taken hold. Make sure they’re clear on{" "}
          <Link
            href="https://blog.totaljobs.com/imposter-phenomenon"
            underline
            target="blank"
          >
            what’s really needed
          </Link>
          .
        </BodyText>
        <BodyText className="smallSpacing">
          They may be heading for burnout trying to be perfect or trying to
          deliver above and beyond what's truly required. Be clear on the
          boundaries and standards you’ve set and reiterate them if needs be.
        </BodyText>
      </Container>
      <Container className="largeSpacing">
        <BarChart
          title="When starting a new job or getting an internal promotion I have felt less like a fake when I received..."
          barColor="Green"
          data={[
            {
              value: 53,
              barLabel:
                "A job description that clearly highlights what’s expected of me"
            },
            {
              value: 50,
              barLabel:
                "Specific feedback about my work and how well I am doing"
            },
            {
              value: 45,
              barLabel: "A clear set of performance indicators and objectives"
            }
          ]}
        />
      </Container>
      <Columns>
        <Column>
          <BodyText className="smallSpacing">
            If you notice your team members dismissing genuine praise, gently
            call it out. When you do give good feedback, remember to provide
            evidence that helps people see why it’s deserved.
          </BodyText>
        </Column>
        <Quote
          content={
            "During my interview for my current role I was asked to describe my most important achievement in the past year. I kept thinking that it was not my achievement, it was a team effort."
          }
          name="Ruth, senior manager in healthcare"
        />
        <Column>
          <BodyText className="smallSpacing">
            Workplace systems such as poor feedback processes can fuel Imposter
            Phenomenon. Yet by understanding this, workplaces can also enjoy
            engagement, equity and productivity gains.
          </BodyText>
        </Column>{" "}
        <Column>
          <BodyText className="smallSpacing">
            By wising up to Imposter Phenomenon, we can all see through the
            illusion.
          </BodyText>
        </Column>
      </Columns>
    </Appear>
  );
};

export default Section7;
