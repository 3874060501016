import React from "react";
import SocialIcon from "./SocialIcon";
import s from "../style.module.css";

const SocialLink = ({ type, href }) => {
  return (
    <a
      href={href}
      aria-label={`share on ${type}`}
      className={s.linkContainer}
      target="blank"
    >
      <SocialIcon type={type} />
    </a>
  );
};

export default SocialLink;
