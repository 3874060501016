import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";

const InternalBar = ({ value, color, onScreen, audioLabel }) => {
  return (
    <span
      className={cn(
        s.innerBar,
        {
          [c[`bg${color}`]]: color
        },
        { [s.onScreen]: onScreen }
      )}
      style={{ width: `${value}%` }}
      aria-label={audioLabel}
    />
  );
};

export default InternalBar;
