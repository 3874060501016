import React, { Fragment } from "react";

const Axis = ({ translatePoint, chartBasePoint, interval, max }) => {
  const createLabelAxis = direction => {
    const xOffset = direction === "x" ? 1 : -7;
    const yOffset = 4;
    const basePosition =
      direction === "x" ? translatePoint(50, 0) : translatePoint(0, 50);
    return {
      label: "50%",
      x: basePosition.x + xOffset,
      y: basePosition.y + yOffset,
      basePosition: basePosition,
      tick: direction === "x" ? 1 : -1
    };
  };

  const axisLabels = [createLabelAxis("x"), createLabelAxis("y")];
  const zeroLabel = translatePoint(0, 0);
  axisLabels.push({
    label: "0%",
    x: zeroLabel.x - 1.5,
    y: zeroLabel.y + 4,
    basePosition: {
      x: 0,
      y: 0
    }
  });

  const axisPaths = [
    `m10 ${chartBasePoint[0] - 5} l50 50 Z`,
    `m${chartBasePoint[0]} ${chartBasePoint[1]} l50 -50 Z`
  ];

  return (
    <Fragment>
      {axisPaths.map((path, index) => (
        <path
          key={index}
          d={path}
          stroke={"#97979d"}
          strokeWidth={0.1}
          strokeDasharray="1,1"
        />
      ))}
      {axisLabels.map(({ label, x, y, basePosition, tick }, index) => (
        <Fragment key={index}>
          <text x={x} y={y} fontSize="3" fill="#6c6d79">
            {label}
          </text>
          {tick && (
            <path
              d={`m${basePosition.x} ${basePosition.y} l${tick} 1 Z`}
              stroke={"black"}
              strokeWidth={0.1}
            />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default Axis;
