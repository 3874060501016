import React, { Fragment } from "react";
import c from "../../../colors.module.css";

const BackgroundLines = ({ chartBasePoint }) => {
  const lines = [10, 30, 50].map((point, index) => {
    return chartBasePoint[1] - point;
  });

  return (
    <Fragment>
      {lines.map((y, index) => (
        <rect
          key={index}
          x="-2500"
          y={y}
          width="5000"
          height="10"
          className={c.fillLightBlueWithOpacity}
        />
      ))}
    </Fragment>
  );
};

export default BackgroundLines;
