import React, { Fragment } from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import ChartArea from "./Elements/ChartArea";
import Bar from "../Bar";
import ChartTitle from "../ChartTitle";
import ChartLabel from "../../typography/ChartLabel";

const BarChart = ({ title, legend, data = [], barColor, innerBarColor }) => {
  return (
    <Fragment>
      <ChartTitle title={title} legend={legend} />
      <ChartArea>
        {data.map(({ barLabel, value, innerValue }, index) => (
          <Fragment key={index}>
            {barLabel && (
              <ChartLabel className={cn(s.outerLabel, c.txtBlack)}>
                {barLabel}
              </ChartLabel>
            )}
            <Bar
              value={value}
              barColor={barColor}
              innerBarColor={innerBarColor}
              innerValue={innerValue}
              audioLabel={
                innerValue
                  ? `${barLabel}. Imposter ${value}%. Non-imposter ${innerValue}%`
                  : `${barLabel} ${value}%`
              }
            />
          </Fragment>
        ))}
      </ChartArea>
    </Fragment>
  );
};

export default BarChart;
