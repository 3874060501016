import React, { Fragment } from "react";

const TDivider = ({ color = "black", strokeWidth = 0.5, chartBasePoint }) => {
  return (
    <Fragment>
      <path
        d={`m${chartBasePoint[0]} 5 l0 100 Z`}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </Fragment>
  );
};

export default TDivider;
