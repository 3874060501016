import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const Subtitle = ({ children, isCentered, underline, className, ...rest }) => {
  return (
    <h3
      className={cn(
        t.subtitle,
        { [t.isCentered]: isCentered },
        { [t.underlineSubtitle]: underline },
        c.txtBlack,
        className
      )}
      {...rest}
    >
      {children}
    </h3>
  );
};

export default Subtitle;
