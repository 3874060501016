import React from "react";
import BodyText from "../../typography/BodyText";
import Link from "../../typography/Link";
import Container from "../../components/Container";
import CirclesWithPercentage from "../../components/CirclesWithPercentage";
import HeadingWithNav from "../../components/HeadingWithNav";
import Quote from "../../components/Quote";
import Columns from "../../components/Columns";
import Column from "../../components/Column";
import BarChart from "../../components/BarChart";
import Appear from "../../components/Appear";

const Section4 = props => {
  return (
    <Appear id="section4" className="extraLargeSpacing">
      <HeadingWithNav activeSection="section4">
        How does Imposter Phenomenon affect us?
      </HeadingWithNav>
      <Container isSmall>
        <BodyText className="largeSpacing">
          Stress, burnout,{" "}
          <Link
            href="https://www.totaljobs.com/insidejob/coping-anxiety-work/"
            underline
            target="blank"
          >
            anxiety
          </Link>{" "}
          and{" "}
          <Link
            href="https://www.totaljobs.com/insidejob/coping-depression-work/"
            underline
            target="blank"
          >
            depression
          </Link>
          . Just a few of the ways that Imposter Phenomenon can disrupt our
          working lives. Here’s why.
        </BodyText>
      </Container>
      <Container className="largeSpacing">
        <BarChart
          title="How do imposters feel when making mistakes?"
          legend={[
            { color: "Pink", label: "Imposter" },
            { color: "Maroon", label: "Non-imposter " }
          ]}
          barColor="Pink"
          innerBarColor="Maroon"
          data={[
            {
              value: 41,
              innerValue: 11,
              barLabel: "Anxious that I'm not capable"
            },
            {
              value: 28,
              innerValue: 8,
              barLabel: "Stew over them for a long time"
            },
            {
              value: 47,
              innerValue: 28,
              barLabel: "I failed to meet my team's expectations"
            },
            {
              value: 68,
              innerValue: 84,
              barLabel: "Want to do better next time"
            }
          ]}
        />
      </Container>
      <Columns>
        <Column>
          <BodyText className="smallSpacing">
            Constantly worrying about ‘being found out’ as a fraud in one's own
            role can be a massive drain on our confidence. This affects our
            performance at work. And the self-fulfilling prophecy is complete.
          </BodyText>
        </Column>
        <Quote
          content={
            "When I took on a managerial role I had to remind myself I had more knowledge and experience than the junior member of staff."
          }
          name={"Anonymous"}
        />
        <Column>
          <BodyText className="smallSpacing">
            Yet when they do score wins in the workplace, people experiencing
            Imposter Phenomenon will often struggle to recognise their own
            achievements.
          </BodyText>
        </Column>
        <Column>
          <BodyText className="smallSpacing">
            They’ll also tend to deflect the praise on to others. Why? They
            genuinely believe their success is down to luck or some other
            external factor.
          </BodyText>
        </Column>
      </Columns>
      <Container isSmall>
        <CirclesWithPercentage
          percentage={70}
          label={"let others take credit for projects they're involved in"}
        />
        <BodyText noPadding>
          Put it all together and Imposter Phenomenon can seriously hamper our
          careers, roadblocking promotions,{" "}
          <Link
            href="https://www.totaljobs.com/insidejob/how-to-ask-for-a-pay-rise/"
            underline
            target="blank"
          >
            pay rises
          </Link>{" "}
          and enjoyment of our work.
        </BodyText>
      </Container>
    </Appear>
  );
};

export default Section4;
