import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import ConcentricCircles from "../ConcentricCircles";
import PercentageWithLabel from "../PercentageWithLabel";
import withOnScreen from "../../hoc/withOnScreen";

const CirclesWithPercentage = ({ percentage, label, onScreen }) => {
  return (
    <div className={cn(s.outerContainer, "smallSpacing")}>
      <div className={s.circleContainer}>
        <ConcentricCircles percentage={percentage} onScreen={onScreen} />
      </div>
      <div className={s.labelContainer}>
        <PercentageWithLabel percentage={percentage} label={label} />
      </div>
    </div>
  );
};

export default withOnScreen(CirclesWithPercentage);
