import React from "react";
import s from "./style.module.css";
import Container from "../Container";

const Column = ({ children, isLeft }) => {
  return (
    <Container className={s.container} isSmall>
      {children}
    </Container>
  );
};

export default Column;
