import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const RelatedSubtitle = ({ children, className, underline, ...rest }) => {
  return (
    <h3
      className={cn(
        t.relatedSubtitle,
        c.txtBlack,
        { [t.underlineSubtitle]: underline },
        className
      )}
      {...rest}
    >
      {children}
    </h3>
  );
};

export default RelatedSubtitle;
