import React from "react";
import BodyText from "../../typography/BodyText";
import Container from "../../components/Container";
import Hero from "../../components/Hero";
import HighlightedText from "../../typography/HighlightedText";

const Opening = props => {
  return (
    <section>
      <Hero>Imposter Phenomenon</Hero>
      <Container isSmall className="extraLargeSpacing">
        <div className="smallSpacing">
          <HighlightedText>I was just lucky to get this job...</HighlightedText>
          <HighlightedText>
            I don’t know as much as people think…
          </HighlightedText>
          <HighlightedText>I’m not experienced enough...</HighlightedText>
          <HighlightedText>I didn’t deserve this promotion...</HighlightedText>
          <HighlightedText>I’m not good enough at my role...</HighlightedText>
          <HighlightedText>I’m going to get found out...</HighlightedText>
        </div>
        <BodyText noPadding>
          Sound familiar? If you’ve had these thoughts, you’ve probably
          experienced the most complex self-deception that most workers will
          face during their careers. Discover what it is. How it works. And how
          to beat it.
        </BodyText>
      </Container>
    </section>
  );
};

export default Opening;
