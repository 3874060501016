import React from "react";
import t from "./typography.module.css";
import cn from "classnames";

const ChartLabel = ({ children, isCentered, className, ...rest }) => {
  return (
    <p className={cn(t.chartLabel, className)} {...rest}>
      {children}
    </p>
  );
};

export default ChartLabel;
