import React from "react";
import t from "./typography.module.css";
import c from "../colors.module.css";
import cn from "classnames";

const BodyText = ({ children, isCentered, noPadding, className, ...rest }) => {
  return (
    <p
      className={cn(
        t.body,
        c.txtBlack,
        { [t.noPadding]: noPadding },
        { [t.isCentered]: isCentered },
        className
      )}
      {...rest}
    >
      {children}
    </p>
  );
};

export default BodyText;
