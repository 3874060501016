import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import Container from "../Container";
import Social from "../Social";
import { ReactComponent as Logo } from "../../assets/logos/Totaljobs.svg";

const Header = props => {
  return (
    <header className={cn(c.bgWhite, s.shadow)}>
      <Container>
        <div className={s.container}>
          <a
            href="https://www.totaljobs.com/"
            aria-label="totaljobs.com"
            target="blank"
          >
            <Logo />{" "}
          </a>
          <Social />
        </div>
      </Container>
    </header>
  );
};

export default Header;
