import React from "react";
import s from "../style.module.css";
import c from "../../../colors.module.css";
import cn from "classnames";
import Arrow from "../../Arrow";

const Controls = ({ highlightImposters, switchHighlight }) => {
  const activeClasses = cn(c.txtWhite, c.bgDarkGreen);
  const inactiveClasses = cn(c.txtGreen, c.bgWhite);
  const buttons = [
    {
      label: "non-imposters",
      classNames: !highlightImposters ? activeClasses : inactiveClasses,
      isImposter: false,
      position: "buttonLeft"
    },
    {
      label: "imposters",
      classNames: highlightImposters ? activeClasses : inactiveClasses,
      isImposter: true,
      position: "buttonRight"
    }
  ];

  return (
    <div className={s.controls}>
      <Arrow className={s.arrow} />
      {buttons.map(({ label, classNames, isImposter, position }, index) => (
        <button
          key={index}
          className={cn(s.button, classNames, { [s[position]]: position })}
          onClick={() => switchHighlight(isImposter)}
          tabIndex={0}
        >
          <span className={s.buttonContent} tabIndex={-1} />
          {label}
        </button>
      ))}
      <Arrow className={cn(s.arrow, s.rightArrow)} />
    </div>
  );
};

export default Controls;
