import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import RelatedSubtitle from "../../typography/RelatedSubtitle";
import RelatedDescription from "../../typography/RelatedDescription";

const Related = ({ title, image, link, children }) => {
  return (
    <div className={cn(s.container, "smallSpacing")}>
      <a href={link} aria-label={title} target="blank">
        <div className={s.innerContainer}>
          <img src={image} alt={title} className={s.image} />
          <RelatedSubtitle underline className={s.title}>
            {title}
          </RelatedSubtitle>
          <RelatedDescription>{children}</RelatedDescription>
        </div>
      </a>
    </div>
  );
};

export default Related;
