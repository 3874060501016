import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";

const ConcentricCircles = ({ percentage, onScreen }) => {
  const outerArea = 100 * 100 * Math.PI;
  const innerArea = (outerArea / 100) * percentage;
  const innerWidth = Math.sqrt(innerArea / Math.PI);
  return (
    <div className={s.container}>
      <div
        className={cn(s.circle, s.outer, c.bgLightBlue, {
          [s.onScreen]: onScreen
        })}
        aria-label={`${percentage}%`}
      >
        <div
          className={cn(s.circle, s.inner, c.bgGreen, {
            [s.onScreen]: onScreen
          })}
          style={{ width: `${innerWidth}%`, height: `${innerWidth}%` }}
          aria-label={`${percentage}%`}
        />
      </div>
    </div>
  );
};

export default ConcentricCircles;
