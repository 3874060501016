import React from "react";
import s from "./style.module.css";
import Container from "../Container";
import PageTitle from "../../typography/PageTitle";
import { ReactComponent as Stripes } from "../../assets/Header.svg";

const Hero = ({ children }) => {
  return (
    <Container className={s.container}>
      <Stripes className={s.svg} />
      <Container isSmall>
        <div className={s.whiteCircle} />
        <div className={s.headerContainer}>
          <PageTitle className={s.title}>{children}</PageTitle>
        </div>
      </Container>
    </Container>
  );
};

export default Hero;
