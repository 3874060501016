import React from "react";
import s from "./style.module.css";
import Subtitle from "../../typography/Subtitle";
import Legend from "../Legend";
import cn from "classnames";

const ChartTitle = ({ isCentered, title, legend }) => {
  return (
    <div className={cn(s.container, { [s.isCentered]: isCentered })}>
      <Subtitle isCentered={isCentered}>{title}</Subtitle>
      {legend && <Legend items={legend} />}
    </div>
  );
};

export default ChartTitle;
