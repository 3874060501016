import React from "react";
import s from "./style.module.css";
import c from "../../colors.module.css";
import cn from "classnames";
import Quotation from "../../typography/Quotation";
import ToolTip from "../../typography/ToolTip";
import Container from "../Container";

import { ReactComponent as QuotationMark } from "../../assets/quotationMark.svg";
import { ReactComponent as LongArrow } from "../../assets/longArrow.svg";

const Quote = ({ content, name, toolTip }) => {
  return (
    <Container isSmall className={cn(s.container, "smallSpacing")}>
      {toolTip && (
        <div className={s.toolTipContainer}>
          <LongArrow className={s.toolTipArrow} />
          <ToolTip className={s.toolTipContent}>{toolTip}</ToolTip>
        </div>
      )}
      <div className={cn(s.coloredBar, c.bgLightBlueWithOpacity)} />
      <div className={cn(s.quotation, c.bgLightBlueWithOpacity)}>
        <QuotationMark className={cn(s.quotationMark, c.txtBlack)} />
      </div>
      <div className={s.content}>
        <Quotation>{content}</Quotation>
        <Quotation className={s.name}>
          <strong>{name}</strong>
        </Quotation>
      </div>
    </Container>
  );
};

export default Quote;
